import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { chienDichApi } from '../../api/chienDichApi';
import { formApi } from '../../api/formApi';
import { formItemApi } from '../../api/formItemApi';
import { formRegisterApi } from '../../api/formRegisterApi';
import { appConst } from '../../app-const';
import { useScreenSizeClass } from '../../utils/media-query';
import { AttendanceSuccess } from '../form-attendance/FormAttendancePage';
import { FormCobobox } from '../form/Editor';
import FormTagBox from '../form/Editor/FormTagbox';
import styles from './FormRegisterPage.module.css';
import { khoiApi } from '../../api/dm_khoi_api';
import { heApi } from '../../api/dm_he_api';
import { phuHuynhApi } from '../../api/phuHuynhApi';
import { useCommonContext } from '../../contexts/common';
import { hocSinhApi } from '../../api/hocsinh_api';
import { showNotify } from '../../helpers/toast';
const FormRegisterPage = ({ isAttendance, dmChienDichId, tsFormId }) => {
  const screenSizeClass = useScreenSizeClass();
  const location = useLocation();
  const params = location.pathname.replace('/form-register/', '').split('/');
  const dm_chiendich_id = dmChienDichId || (params.length > 0 ? parseInt(params[0]) : 0);
  const ts_form_id = tsFormId || (params.length > 1 ? parseInt(params[1]) : 0);

  const [ts_form, set_ts_form] = useState({});
  const [ts_form_items, set_ts_form_items] = useState([]);
  const [ts_form_register_result_id, set_ts_form_register_result_id] =
    useState(0);
  const [chienDich, setChienDich] = useState();
  const { register, trigger, errors } = useForm();
  const { dm_coso_id } = useSelector(x => x.coSo)
  const { nam_hoc } = useSelector(x => x.namHoc)
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    dm_chiendich_id: dm_chiendich_id,
    readOnly: false
  });
  const [tsHocSinhId, setTsHocSinhId] = useState(0);
  const [mssError, setMssError] = useState();
  const [color, setColor] = useState();

  useEffect(() => {
    if (dm_chiendich_id > 0 && ts_form_id > 0 && ts_form_register_result_id > 0) {
      //lưu cache để điểm danh
      localStorage.setItem(`${dm_chiendich_id}-${ts_form_id}`, ts_form_register_result_id);
    }
  }, [ts_form_register_result_id, ts_form_id, dm_chiendich_id])

  useEffect(() => {
    handleReloadForm();
  }, [ts_form_id]);
  useEffect(() => {
    handleReloadChienDich();
  }, [dm_chiendich_id])
  useEffect(() => {
    if (ts_form && ts_form.id > 0) {
      setFormData({
        ...formData,
        ts_form_id: ts_form.id,
      });
      handleReloadFormItems();
    }
  }, [ts_form]);
  useEffect(() => {
    console.log(ts_form_items);
    setFormData({
      ...formData,
      result: ts_form_items.map((x) => {
        return {
          ts_form_item_id: x.id,
          ts_form_editor_id: x.ts_form_editor_id,
          value: '',
        };
      }),
    });
  }, [ts_form_items]);
  const phone_number = useMemo(() => {
    if (formData.result) {
      const phone_number_result = formData.result.find(
        (x) => x.ts_form_editor_id == appConst.formEditorFixed.dien_thoai
      );
      if (phone_number_result) return phone_number_result.value;
    }

    return '';
  }, [formData]);
  useEffect(() => {
    if (isAttendance) {
      hanleAutoFill();
    }

  }, [phone_number, isAttendance])
  const handleReloadChienDich = async () => {
    const res = await chienDichApi.Detail(dm_chiendich_id)
    if (res.is_success) {
      setChienDich(res.data)

    }
  }
  const handleReloadForm = async () => {
    const res = await formApi.Detail(ts_form_id);
    if (res.is_success) {
      set_ts_form(res.data);
    }
  };
  const handleReloadFormItems = async () => {
    const res = await formItemApi.Select(ts_form.id);
    console.log(res.data);
    if (res.is_success) {
      const datas = res.data.filter((val) => val.is_show === true);
      set_ts_form_items(datas);

    }
  };
  const loadValueHS = async (value) => {
      var res = await hocSinhApi.SelectByMaHs(value, chienDich.dm_coso_id);
      if (res && res.data) {
        var res2 = await hocSinhApi.Detail(res.data.id, chienDich.nam_hoc);
        if (res2) {
          var phCha = await hocSinhApi.SelectPhuHuynh(res.data.id, 1);
          var phMe = await hocSinhApi.SelectPhuHuynh(res.data.id, 2);
          var hs = res2.data;
          console.log(hs);
          setTsHocSinhId(hs.id);
          setFormData({
            ...formData,
            result: formData.result.map((x) => {
              switch (x.ts_form_editor_id) {
                case 2000:
                  return {
                    ...x,
                    value: hs.ho_ten,
                    readOnly: true
                  };
                case 2001:
                  return {
                    ...x,
                    value: hs.dm_gioitinh_id.toString(),
                    readOnly: true
                  };
                case 2002:
                  const date = moment(hs.ngay_sinh).format('DD/MM/YYYY');
                  return {
                    ...x,
                    value: date,
                    readOnly: true
                  };
                case 2003:
                  return {
                    ...x,
                    value: hs.dm_quoctich_id.toString(),
                    readOnly: true
                  };
                case 2004:
                  return {
                    ...x,
                    value: hs.dm_truong_id.toString(),
                    readOnly: true
                  };
                case 2005:
                  return {
                    ...x,
                    value: hs.dm_khoi_id.toString(),
                    readOnly: true
                  };
                case 2006:
                  return {
                    ...x,
                    value: hs.dm_he_id.toString(),
                    readOnly: true
                  };
                case 2007:
                  return {
                    ...x,
                    value: hs.truong_hoc_cu,
                    readOnly: hs.truong_hoc_cu ? true : false
                  };
                case 2008:
                  return {
                    ...x,
                    value: hs.dm_nguonbiettoi_id,
                    readOnly: hs.dm_nguonbiettoi_id == '' ? true : false
                  };
                case 2009:
                  return {
                    ...x,
                    value: hs.nam_hoc,
                    readOnly: true
                  };
                case 2010:
                  return {
                    ...x,
                    value: hs.dia_chi_tt,
                    readOnly: (hs.dia_chi_tt !== null && hs.dia_chi_tt !== '') ? true : false
                  };
                case 2011:
                  return {
                    ...x,
                    value: hs.dia_chi_noi_sinh,
                    readOnly: (hs.dia_chi_noi_sinh !== null && hs.dia_chi_noi_sinh !== '') ? true : false
                  };
                case 2012:
                  return {
                    ...x,
                    value: hs.que_quan,
                    readOnly: (hs.que_quan !== null && hs.que_quan !== '') ? true : false
                  };
                case 2033:
                  return {
                    ...x,
                    value: hs.ma_dinh_danh,
                    readOnly: (hs.ma_dinh_danh !== null && hs.ma_dinh_danh.trim().toString() !== '')?true:false
                  };
                case 2034:
                  return {
                    ...x,
                    value: hs.ma_so_bhxh,
                    readOnly: (hs.ma_so_bhxh !== null && hs.ma_so_bhxh.trim().toString() !== '')?true:false
                  };
                case 2035:
                  return {
                    ...x,
                    value: hs.doi_tuong_chinh_sach,
                    readOnly: (hs.doi_tuong_chinh_sach !== null && hs.doi_tuong_chinh_sach.trim().toString() !== '')?true:false
                  };
                case 2013:
                  return {
                    ...x,
                    value: (phCha && phCha.data) ? phCha.data.ho_ten : "",
                    readOnly: (phCha && phCha.data) ? true : false
                  };
                case 2014:
                  return {
                    ...x,
                    value: (phCha && phCha.data) ? phCha.data.email : "",
                    readOnly: (phCha && phCha.data && phCha.data.email) ? true : false
                  };
                case 2015:
                  return {
                    ...x,
                    value: (phCha && phCha.data) ? phCha.data.dien_thoai : "",
                    readOnly: (phCha && phCha.data) ? true : false
                  };
                case 2016:
                  return {
                    ...x,
                    value: (phCha && phCha.data) ? phCha.data.nghe_nghiep : "",
                    readOnly: (phCha && phCha.data && phCha.data.nghe_nghiep) ? true : false
                  };
                case 2017:
                  return {
                    ...x,
                    value: (phCha && phCha.data) ? phCha.data.noi_lam_viec : "",
                    readOnly: (phCha && phCha.data && phCha.data.noi_lam_viec) ? true : false
                  };
                case 2018:
                  return {
                    ...x,
                    value: (phCha && phCha.data) ? phCha.data.chung_minh_thu : "",
                    readOnly: (phCha && phCha.data && phCha.data.chung_minh_thu) ? true : false
                  };
                case 2019:
                  return {
                    ...x,
                    value: (phMe && phMe.data) ? phMe.data.ho_ten : "",
                    readOnly: (phMe && phMe.data && phMe.data.ho_ten) ? true : false
                  };
                case 2020:
                  return {
                    ...x,
                    value: (phMe && phMe.data) ? phMe.data.email : "",
                    readOnly: (phMe && phMe.data && phMe.data.email) ? true : false
                  };
                case 2021:
                  return {
                    ...x,
                    value: (phMe && phMe.data) ? phMe.data.dien_thoai : "",
                    readOnly: (phMe && phMe.data && phMe.data.dien_thoai) ? true : false
                  };
                case 2022:
                  return {
                    ...x,
                    value: (phMe && phMe.data) ? phMe.data.nghe_nghiep : "",
                    readOnly: (phMe && phMe.data && phMe.data.nghe_nghiep) ? true : false
                  };
                case 2023:
                  return {
                    ...x,
                    value: (phMe && phMe.data) ? phMe.data.noi_lam_viec : "",
                    readOnly: (phMe && phMe.data && phMe.data.noi_lam_viec) ? true : false
                  };
                case 2024:
                  return {
                    ...x,
                    value: (phMe && phMe.data) ? phMe.data.chung_minh_thu : "",
                    readOnly: (phMe && phMe.data && phMe.data.chung_minh_thu) ? true : false
                  };
                case 2032:
                  return {
                    ...x,
                    value: value
                  };

                default:
                  return { ...x };
              }
            }),
          });
        }
        setMssError("Mã học sinh đã khớp với hệ thống");
        setColor("#8bc34a");
      }else{
        setMssError("Mã học sinh không đúng");
        setColor("red");
        // showNotify({
        //   text: 'Mã học sinh không đúng',
        //   type: 'error',
        // });
      }
  
  }
  const handleValueChanged = async (ts_form_editor_id, ts_form_item_id, value) => {
    
      setFormData({
        ...formData,
        result: formData.result.map((x) => {
          if (x.ts_form_item_id == ts_form_item_id) {
            return {
              ...x,
              ts_form_item_id: x.ts_form_item_id,
              value: value,
            };
          } else {
            return { ...x };
          }
        }),
      });
  };
  const handleSubmit = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        setIsSaving(true);
        var rs = formData.result.map((x) => {
          return {
            ts_form_item_id: x.ts_form_item_id,
            value: x.value,
            ts_form_editor_id: x.ts_form_editor_id,
          };
        });
        const res = await formRegisterApi.Insert({
          dm_chiendich_id: dm_chiendich_id,
          ts_form_id: formData.ts_form_id,
          phone_number: phone_number,
          is_attendanced: isAttendance || false,
          result: rs,
        });
        setIsSaving(false);
        if (res.is_success) {
          set_ts_form_register_result_id(res.data);
          try {
            let _data = {
              nam_hoc: null,
              ho_ten: null,
              ngay_sinh_timestamp: null,
              dm_gioitinh_id: null,
              dm_he_id: null,
              dm_khoi_id: null,
              dm_truong_id: null,
              truong_hoc_cu: null,
              dm_nguonbiettoi_id: null,
              dm_quoctich_id: null,
              nam_hoc: null,
              dia_chi_tt: null,
              dia_chi_noi_sinh: null,
              que_quan: null,
              ts_form_register_result_id: null,
              dien_thoai_cha: null,
              dien_thoai_me: null,
              dien_thoai_giam_ho: null,
              ma_dinh_danh: null,
              ma_so_bhxh: null,
              doi_tuong_chinh_sach: null
            };
            rs.forEach(element => {
              _data.ts_form_register_result_id = res.data;
              switch (element.ts_form_editor_id) {
                case 2000:
                  _data.ho_ten = element.value;
                  break;
                case 2001:
                  _data.dm_gioitinh_id = element.value != null ? parseInt(element.value) : 0;
                  break;
                case 2002:
                  _data.ngay_sinh_timestamp = element.value ? moment(element.value, 'DD/MM/YYYY').toDate().getTime() : 0;
                  break;
                case 2003:
                  _data.dm_quoctich_id = element.value != null ? parseInt(element.value) : 0;
                  break;
                case 2004:
                  _data.dm_truong_id = element.value != null ? parseInt(element.value) : 0;
                  break;
                case 2005:
                  _data.dm_khoi_id = element.value != null ? parseInt(element.value) : 0;
                  break;
                case 2006:
                  _data.dm_he_id = element.value != null ? parseInt(element.value) : 0;
                  break;
                case 2007:
                  _data.truong_hoc_cu = element.value;
                  break;
                case 2008:
                  _data.dm_nguonbiettoi_id = element.value != null ? parseInt(element.value) : 0;
                  break;
                case 2009:
                  _data.nam_hoc = element.value;
                  break;
                case 2010:
                  _data.dia_chi_tt = element.value;
                  break;
                case 2011:
                  _data.dia_chi_noi_sinh = element.value;
                  break;
                case 2012:
                  _data.que_quan = element.value;
                  break;
                case 2033:
                  _data.ma_dinh_danh = element.value;
                  break;
                case 2034:
                  _data.ma_so_bhxh = element.value;
                  break;
                case 2035:
                  _data.doi_tuong_chinh_sach = element.value;
                  break;
                case 2015:
                  _data.dien_thoai_cha = element.value;
                  break;
                case 2021:
                  _data.dien_thoai_me = element.value;
                  break;
                case 2030:
                  _data.dien_thoai_giam_ho = element.value;
                  break;
                default:
                  break;
              }
            });
            const hocSinh = await insertHS(_data);
            console.log(hocSinh);
            var ts_hocsinh_id = 0;
            if (hocSinh) {
              ts_hocsinh_id = hocSinh.data;
            }
            await formRegisterApi.SendEmail({
              dm_chiendich_id: dm_chiendich_id,
              ts_form_id: formData.ts_form_id,
              phone_number: phone_number,
              ts_hocsinh_id: ts_hocsinh_id,
              is_attendanced: isAttendance || false,
              result: rs,
            });
          } catch (error) {
            console.log(error);
          }
        }else{
          showNotify({ text: res.message, type: "error" })
        }
      }
    });

  };

  const insertHS = async (value) => {
    if (tsHocSinhId > 0) {
      var res2 = await hocSinhApi.Detail(tsHocSinhId, chienDich.nam_hoc);
      if (value.dm_khoi_id == null || isNaN(value.dm_khoi_id)) {
        value.dm_khoi_id = res2.data.dm_khoi_id;
      }
      if (value.dm_he_id == null) {
        value.dm_he_id = res2.data.dm_he_id;
      }
      if (value.ngay_sinh_timestamp == null) {
        value.ngay_sinh_timestamp = res2.data.ngay_sinh_timestamp;
      }
    }
    var isInsert = true;
    if (value.ho_ten == null) {
      isInsert = false;
    }
    if (value.dm_khoi_id == null || isNaN(value.dm_khoi_id)) {
      isInsert = false;
    }
    if (value.dm_he_id == null) {
      isInsert = false;
    }
    if (value.ngay_sinh_timestamp == null) {
      isInsert = false;
    }
    var coso_id = dm_coso_id;
    if (isInsert) {
      const resKhoi = await khoiApi.Detail(value.dm_khoi_id);
      const resHe = await heApi.Detail(value.dm_he_id);
      var dm_truong_id = value.dm_truong_id != null ? value.dm_truong_id : 0;
      if (resKhoi.is_success) {
        dm_truong_id = resKhoi.data.dm_truong_id;
      }
      if (resHe.is_success) {
        coso_id = resHe.data.dm_coso_id;
      }
      var namHoc = value.nam_hoc != null ? value.nam_hoc : nam_hoc;
      var formDataSubmit = {
        id: tsHocSinhId,
        nam_hoc: namHoc,
        nam_nhap_hoc: namHoc,
        ho_ten: value.ho_ten,
        ngay_sinh_timestamp: value.ngay_sinh_timestamp,
        ngay_nhap_hoc_timestamp: new Date().getTime(),
        dm_gioitinh_id: (value.dm_gioitinh_id && value.dm_gioitinh_id != null && value.dm_gioitinh_id != NaN) ? value.dm_gioitinh_id : 1,
        dm_he_id: value.dm_he_id,
        dm_coso_id: coso_id,
        dm_dantoc_id: 1,
        dm_tongiao_id: 1,
        dm_chiendich_id: dm_chiendich_id,
        dm_khoi_id: value.dm_khoi_id,
        dm_truong_id: dm_truong_id,
        truong_hoc_cu: value.truong_hoc_cu,
        dm_nguonbiettoi_id: (value.dm_nguonbiettoi_id && value.dm_nguonbiettoi_id != null && value.dm_nguonbiettoi_id != NaN
          && value.dm_nguonbiettoi_id != undefined && value.dm_nguonbiettoi_id != 'NaN') ? value.dm_nguonbiettoi_id : 0,
        dm_quoctich_id: value.dm_quoctich_id ? value.dm_quoctich_id : 1,
        dia_chi_tt: value.dia_chi_tt,
        dia_chi_noi_sinh: value.dia_chi_noi_sinh,
        que_quan: value.que_quan,
        ma_dinh_danh: value.ma_dinh_danh,
        ma_so_bhxh: value.ma_so_bhxh,
        doi_tuong_chinh_sach: value.doi_tuong_chinh_sach,
        so_dien_thoai_bo: value.dien_thoai_cha,
        so_dien_thoai_me: value.dien_thoai_me
      };
      var res = await hocSinhApi.Insert(formDataSubmit);
      console.log(res);
      if (res.is_success) {
        // if (value.dien_thoai_cha != null && value.dien_thoai_cha != "") {
        //   if (value.dien_thoai_cha.toString().startsWith('0') && value.dien_thoai_cha.toString().length == 10) {
        //     const formDataSubmit = {
        //       dien_thoai: value.dien_thoai_cha,
        //       ts_hocsinh_id: res.data,
        //       dm_coso_id: dm_coso_id,
        //       dm_quanhe_id: 1
        //     };
        //     await quanHeGiaDinhApi.Insert(formDataSubmit);
        //   }
        // }
        // if (value.dien_thoai_me != null && value.dien_thoai_me != "") {
        //   if (value.dien_thoai_me.toString().startsWith('0') && value.dien_thoai_me.toString().length == 10) {
        //     const formDataSubmit = {
        //       dien_thoai: value.dien_thoai_me,
        //       ts_hocsinh_id: res.data,
        //       dm_coso_id: dm_coso_id,
        //       dm_quanhe_id: 2
        //     };
        //     await quanHeGiaDinhApi.Insert(formDataSubmit);
        //   }
        // }
        // if (value.dien_thoai_giam_ho != null && value.dien_thoai_giam_ho != "") {
        //   if (value.dien_thoai_giam_ho.toString().startsWith('0') && value.dien_thoai_giam_ho.toString().length == 10) {
        //     const formDataSubmit = {
        //       dien_thoai: value.dien_thoai_giam_ho,
        //       ts_hocsinh_id: res.data,
        //       dm_coso_id: dm_coso_id,
        //       dm_quanhe_id: 3
        //     };
        //     await quanHeGiaDinhApi.Insert(formDataSubmit);
        //   }
        // }
        await formRegisterApi.UpdateHocSinh(
          {
            ts_hocsinh_id: res.data,
            id: value.ts_form_register_result_id
          }
        );
      }
      return res;
    }

  }

  const hanleAutoFill = async () => {
    if (dm_chiendich_id > 0 && phone_number != "") {
      const res = await formRegisterApi.SelecDetailListByPhone(0, dm_chiendich_id, phone_number);
      console.log("...........................DMMM");
      if (res.is_success) {
        setFormData({
          ...formData,
          result: formData.result.map((x) => {
            const existedData = res.data.find(y => y.ts_form_item_id == x.ts_form_item_id)
            return {
              ...x,
              value: existedData ? existedData.value : "",
            };

          }),
        });
      }
    }
  }
  if ((ts_form_register_result_id > 0) && isAttendance) {
    return <AttendanceSuccess />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <div className={styles.form + ' ' + screenSizeClass}>

          <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
            <img className={styles.cover_img_title} src={ts_form.cover_img} />
            {/* &nbsp; */}
          </div>
          <div className={styles.form_area}>
            <div className={styles.form_group}>
              <div className={styles.caption}>
                <p>
                  {/* <i className="fas fa-poll" style={{ marginRight: 10 }}></i> */}
                  {ts_form.name}
                </p>
              </div>
              {chienDich && chienDich.is_active && chienDich.top_description_html &&
                <div className={styles.top_description}>
                  <div dangerouslySetInnerHTML={{ __html: chienDich.top_description_html }} />
                </div>
              }
              {(!chienDich || !chienDich.is_active) && (
                <div className={styles.result}>
                  <img src="../../../images/thank-you.png" />
                  <p className={styles.thank_caption}>
                    Chiến dịch đã hết hạn đăng ký, vui lòng liên hệ bộ phận Tuyển sinh.
                  </p>
                </div>
                )}
              {(chienDich && chienDich.is_active) && (ts_form_register_result_id > 0) && !isAttendance && 
              (!chienDich.noti_description_html || chienDich.noti_description_html == '<div><br></div>') && (

                <div className={styles.result}>
                  <img src="../../../images/thank-you.png" />
                  <p className={styles.thank_caption}>
                    Quý Phụ huynh đã đăng ký thành công. Hệ thống đã gửi email tới hòm thư Quý Phụ huynh đăng ký.
                  </p>
                  <p className={styles.description}>
                    Phòng tuyển sinh sẽ liên hệ lại với phụ huynh trong thời gian sớm nhất.
                  </p>
                </div>
              )}
               {(chienDich && chienDich.is_active) && (ts_form_register_result_id > 0) && !isAttendance && 
                (chienDich.noti_description_html && chienDich.noti_description_html != '<div><br></div>') && (

                <div className={styles.result}>
                  <img src="../../../images/thank-you.png" />
                  <p className={styles.thank_caption}>
                    <div dangerouslySetInnerHTML={{ __html: chienDich.noti_description_html }} />
                  </p>
                </div>
              )}
            </div>


            {(chienDich && chienDich.is_active) && ts_form_register_result_id == 0 && (
              <div>
                {ts_form_items.map((x) => {
                  console.log(x);
                  console.log(formData);
                  const existedData = formData.result.find(y => y.ts_form_item_id == x.id);
                  console.log(existedData);
                  var parentId = 0;
                  // if (x.ts_form_editor_id == 2006) {
                  //   const khoiData = formData.result.find(y => y.ts_form_editor_id == 2005);
                  //   if (khoiData && khoiData.value != null && khoiData.value != "") {
                  //     parentId = parseInt(khoiData.value);
                  //   }
                  // }
                  // if (x.ts_form_editor_id == 2005) {
                  //   const khoiData = formData.result.find(y => y.ts_form_editor_id == 2004);
                  //   if (khoiData && khoiData.value != null && khoiData.value != "") {
                  //     parentId = parseInt(khoiData.value);
                  //   }
                  // }
                  console.log(parentId);
                  return (
                    <div key={x.id} className={styles.form_group}>
                      <div className={styles.form_label}>{x.label}
                        {x.is_required && <span style={{ color: "red" }}> *</span>}
                      </div>

                      {x.ts_form_editor_type_id == appConst.formEditorType.text_box && (
                        <TextBox
                          stylingMode="underlined"
                          placeholder={x.help_text}
                          value={existedData?.value}
                          disabled={tsHocSinhId > 0 && x.ts_form_editor_id < 2032 && existedData.readOnly == true}
                          onValueChanged={(e) => {
                            if (e.event) {
                              handleValueChanged(x.ts_form_editor_id, x.id, e.value);
                              trigger(`F_${existedData?.ts_form_item_id}`)
                              // trigger();
                            }
                          }}
                        />
                      )}
                       {x.ts_form_editor_id == 2032 && (
                      <div style={{marginTop: "10px"}}>
                        <p style={{color: color}}>
                         {mssError}
                        </p>
                        <button style={{width: "100px"}} onClick={() => {
                          if (!isSaving) {
                            loadValueHS(existedData?.value)
                          }
                        }} className={styles.btnSubmit}>
                          {`Xác nhận`}
                        </button>
                      </div>
                      )}
                      {x.ts_form_editor_type_id == appConst.formEditorType.combobox && (
                        <FormCobobox
                          ts_form_editor_id={x.ts_form_editor_id}
                          dm_coso_id={ts_form.dm_coso_id}
                          disabled={tsHocSinhId > 0 && x.ts_form_editor_id < 2032 && existedData.readOnly}
                          parent_id={parentId}
                          value={existedData?.value}
                          onValueChanged={(value) => {
                            handleValueChanged(x.ts_form_editor_id, x.id, value);
                            trigger(`F_${existedData?.ts_form_item_id}`)
                          }}
                        />
                      )}
                      {x.ts_form_editor_type_id == appConst.formEditorType.tagbox && (
                        <FormTagBox
                          ts_form_editor_id={x.ts_form_editor_id}
                          dm_coso_id={ts_form.dm_coso_id}
                          disabled={tsHocSinhId > 0 && x.ts_form_editor_id < 2032 && existedData.readOnly}
                          value={JSON.parse(existedData?.value ? existedData?.value : "[]")}
                          onValueChanged={(value) => {
                            handleValueChanged(x.ts_form_editor_id, x.id, JSON.stringify(value));
                            trigger(`F_${existedData?.ts_form_item_id}`)
                          }}
                        />
                      )}
                      {x.ts_form_editor_type_id == appConst.formEditorType.label && (
                        <span>{x.name}</span>
                      )}
                      {existedData?.value && existedData?.value != 'Invalid date' && x.ts_form_editor_type_id == appConst.formEditorType.date_box && (
                        <DateBox
                          stylingMode="underlined"
                          displayFormat={'dd/MM/yyyy'}
                          placeholder={x.help_text}
                          disabled={tsHocSinhId > 0 && x.ts_form_editor_id < 2032 && existedData?.value}
                          value={existedData?.value ? moment(existedData?.value, 'DD/MM/YYYY') : ""}
                          onValueChanged={(e) => {
                            if (e.event) {
                              const date = moment(e.value).format('DD/MM/YYYY');
                              console.log(date);
                              handleValueChanged(x.ts_form_editor_id, x.id, date != "Invalid date" ? date : undefined);
                              trigger(`F_${existedData?.ts_form_item_id}`)
                            }
                          }}
                        />
                      )}
                      {(!existedData?.value || existedData?.value == 'Invalid date') && x.ts_form_editor_type_id == appConst.formEditorType.date_box && (
                        <DateBox
                          stylingMode="underlined"
                          placeholder={x.help_text}
                          displayFormat={'dd/MM/yyyy'}
                          disabled={tsHocSinhId > 0 && x.ts_form_editor_id < 2032 && existedData?.value}
                          value={existedData?.value ? moment(existedData?.value, 'DD/MM/YYYY') : ""}
                          onValueChanged={(e) => {
                            if (e.event) {
                              const date = moment(e.value).format('DD/MM/YYYY');
                              handleValueChanged(x.ts_form_editor_id, x.id, date);
                              trigger(`F_${existedData?.ts_form_item_id}`)
                            }
                          }}
                        />
                      )}
                      {/* <div className={styles.form_help_text}>{x.help_text}</div> */}
                      <input
                        name={`F_${existedData?.ts_form_item_id}`}
                        className="hide"
                        defaultValue={existedData?.value}
                        ref={register({ required: x.is_required })}
                      ></input>
                      {errors[`F_${existedData?.ts_form_item_id}`] && (
                        <Label basic color="red" pointing>
                          {`Vui lòng điền ${x.help_text}`}
                        </Label>
                      )}
                    </div>
                  );
                })}
                {chienDich && chienDich.bottom_description_html &&
                  <div className={styles.form_group}>
                    <div className={styles.bottom_description}>
                      <div dangerouslySetInnerHTML={{ __html: chienDich.bottom_description_html }} />
                    </div>
                  </div>
                }
                <div className={styles.form_actions}>
                  <button onClick={() => {
                    if (!isSaving) {
                      handleSubmit()
                    }
                  }} className={styles.btnSubmit}>
                    {!isSaving ? `Xác nhận` : `Đang cập nhật ...`}
                  </button>
                  {/* <Button
                    text={'Xác nhận'}
                    width={'100%'}
                    type="default"
                    onClick={handleSubmit}
                    disabled={isSaving}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormRegisterPage;
